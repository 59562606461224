import { authHeader, handleResponse } from './auth-header';

export const patientService = {
    getPatient,
};

function getPatient(search, existingPage, perPage, clinicID, doctorID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    };

    return fetch(import.meta.env.VITE_API_URL + `patient?search=` + search + `&page=` + existingPage + `&perPage=` + perPage + `&clinicID=` + clinicID + `&doctorID=` + doctorID, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}